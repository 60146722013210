import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import DarkModeToggle from "../Darkmode/DarkModeToggle";
import DownloadButton from "../Download/DownloadButton";
import { SignedIn, SignedOut } from "../UserState/UserState";
import UserIcon from "../../assets/user.svg";
import { signOut } from "../../Firebase/UserUtils"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import "./Header.scss";

class DesktopHeader extends Component {
  static propTypes = {
    searchbarValue: PropTypes.string.isRequired,
    searchHandler: PropTypes.func.isRequired,
    setSearchbarValue: PropTypes.func.isRequired,
  };

  handleLogout = () => {
    signOut().then(() => {
      this.props.history.push('/login');
    });
  };

  handleSecureClick = async (user, addPointsToUser, fetchUserPoints) => {
    const result = await addPointsToUser(user.uid, 1);

    if (result === "limit") {
      toast.error("Você já atingiu o limite de 5 cliques hoje.");
    } else {
      await fetchUserPoints(user.uid); 
      this.secureLink.handleClick(); 
    }
  };

  render() {
    const { searchbarValue, searchHandler, setSearchbarValue } = this.props;

    return (
      <>
        <header id="app-header-desktop" className="app-header">
          <Searchbar
            value={searchbarValue}
            search={searchHandler}
            setSearchbarValue={setSearchbarValue}
          />
          <div className="darkmode-toggle">
          
            <DownloadButton />
            
            <DarkModeToggle />
          </div>
          <div className="user-actions">
            <SignedIn>
              {user => (
                <button onClick={this.handleLogout} className="user-button">
                  <UserIcon fill="currentColor" />
                  <span className="nav-text">Logout</span>
                </button>
              )}
            </SignedIn>
            <SignedOut>
              <button onClick={() => this.props.history.push('/login')} className="user-button">
                <UserIcon fill="currentColor" />
                <span className="nav-text">Login</span>
              </button>
            </SignedOut>
          </div>
        </header>
        <ToastContainer /> {/* Certifique-se de que isso esteja no nível superior */}
      </>
    );
  }
}

export default withRouter(DesktopHeader);
